import React, { Component } from "react";
import "./ComboButton.css";

export type SelectOption = {
  label: string;
  value: string;
};

interface IComboButton {
  initialValue?: number;
  onSelect?: Function;
  options?: Array<SelectOption | string>;
  wrapperClassNames?: string;
  wrapperStyle?: Object;
  classNames?: Object;
  icon?: Boolean;
  menu?: Boolean;
  inline?: Boolean;
}

export class ComboButton extends Component<IComboButton> {
  comboButton?: HTMLDivElement = undefined;
  dropdown?: HTMLUListElement = undefined;

  static defaultProps = {
    onSelect: () => {},
    options: [] as Array<Object | string>,
    wrapperClassNames: "text-prowl-blue border-prowl-blue",
    classNames: "border-prowl-blue",
    icon: false,
    menu: false,
  };

  state = {
    open: false,
    selected: this.props.initialValue || undefined,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (this.state.open && !this.comboButton.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  };

  toggleOpen = () => this.setState((props: any) => ({ open: !props.open }));

  handleSelect = (value = 0) => () => {
    const { onSelect, options } = this.props;

    if (!this.props.menu) this.setState({ selected: value });
    this.setState({ open: false });

    const option = options[value - 1];
    onSelect(typeof option == "object" ? { ...option } : { label: option });
  };

  render() {
    const {
      children,
      options,
      wrapperClassNames,
      wrapperStyle = {},
      classNames,
      icon,
      inline,
    } = this.props;
    const { open, selected } = this.state;

    const selectedItem: SelectOption | string =
      options[Math.max(selected - 1, 0)];

    const text: string | undefined =
      typeof selectedItem === "object" ? selectedItem.label : selectedItem;

    return (
      <div className="inline-block" ref={(node) => (this.comboButton = node)}>
        <div
          style={wrapperStyle}
          className={`${
            icon ? "" : "border rounded mb-1"
          }  relative text-base uppercase cursor-pointer  max-w-xs ${wrapperClassNames} ${
            inline ? "inline" : "inline-block"
          }`}
        >
          <div
            onClick={this.toggleOpen}
            role="button"
            className="inline-flex flex-shrink flex-no-grow items-center"
          >
            <span
              className={` ${icon ? "" : "py-1 px-3"} ${
                icon ? "" : "border-r"
              }  select-none truncate ${classNames}`}
              title={text}
            >
              {text || this.props.children}
            </span>
            {!icon || !inline ? (
              <div
                style={{
                  width: 0,
                  height: 0,
                  border: "5px solid transparent",
                  borderTopColor: "currentColor",
                }}
                className="mx-2 mt-1"
              />
            ) : (
              ""
            )}
          </div>
          {open && (
            <div
              className="absolute  text-sm z-50 pt-1"
              style={{ top: "100%" }}
            >
              <ul
                style={{
                  top: 0,
                  right: 0,
                }}
                className="block min-w-full shadow-md"
                ref={(el) => (this.dropdown = el)}
              >
                {!!selected && (
                  <li
                    onClick={this.handleSelect(0)}
                    className="inline__selections__item hover:bg-grey-lighter cursor-pointer whitespace-no-wrap"
                  >
                    <span>-- {children}</span>
                  </li>
                )}
                {options
                  .filter((o) => (typeof o == "object" ? !!o.label : !!o))
                  .map((option, i) => (
                    <li
                      onClick={this.handleSelect(i + 1)}
                      className="inline__selections__item hover:bg-grey-lighter cursor-pointer whitespace-no-wrap"
                      data-value={
                        typeof option === "object" ? option.value : option
                      }
                      key={i}
                    >
                      <span>
                        {typeof option === "object" ? option.label : option}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
