import React, { FunctionComponent } from 'react';
import './Button.css';

interface IButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {}

/**
 * The `Button` component is used for Buttons
 */
const Button: FunctionComponent<IButtonProps> = props => {
    const { className = '', disabled = false } = props

    const classes = disabled ? className.concat(' button--disabled') : className

    return <button {...props} className={`button ${classes}`} />
}

Button.displayName = 'Button'

const PrimaryButton: FunctionComponent<IButtonProps> = props => {
    const { className = '' } = props

    return <Button {...props} className={`button--primary ${className}`} />
}

PrimaryButton.displayName = 'PrimaryButton'

const GhostButton: FunctionComponent<IButtonProps> = props => {
    const { className = '' } = props

    return (
        <Button
            {...props}
            className={`button--plain button--rounded button--unboxed ${className}`}
        />
    )
}

GhostButton.displayName = 'GhostButton'

const ShyButton: FunctionComponent<IButtonProps> = props => {
    const { className = '' } = props

    return <Button {...props} className={`button--unboxed ${className}`} />
}

ShyButton.displayName = 'ShyButton'

export { Button, PrimaryButton, GhostButton, ShyButton };

