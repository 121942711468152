import React, { FunctionComponent } from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'
import { ShyButton } from '../Button'
import { Card } from '../Card'



const InfoBlockLoader: FunctionComponent<IContentLoaderProps> = props => (
    <ContentLoader {...props}>
        <rect x="9" y="13" width="74" height="8" />
        <rect x="9" y="35" width="200" height="43" />
        <rect x="9" y="94" width="200" height="9" />

        <rect x="9" y="110" width="200" height="9" />

        <rect x="96" y="158" width="110" height="14" />
    </ContentLoader>
)

interface InfoBlockType {
    hidden?: boolean
    isLoaded?: boolean
    height?: number
    width?: number
    title?: string
    subHeading?: string
    content?: string 
    link?: string
    linkDesc?: string
    className?: string
}

export const InfoBlock: FunctionComponent<InfoBlockType> = props => {
    const {
        hidden = false,
        isLoaded = false,
        height = 135,
        width = 250
    } = props
    return (
        !hidden && (
            <div className={`flex-1 mx-1 my-3  `.concat(props.className)}>
                {isLoaded ? (
                    <Card className='flex flex-col flex-1 bg-white p-4'>
                        {!!props.title && (
                            <h2 className="text-grey-dark font-sans font-bold text-sm">
                                {props.title}
                            </h2>
                        )}
                        {!!props.subHeading && (
                            <h3 className="font-extrabold text-primary-dark  text-xl mb-1">
                                {props.subHeading}
                            </h3>
                        )}
                        <p className="mb-4 text-sm">{props.content}</p>
                        {!!props.link && (
                            <div className="flex flex-1 flex-col justify-end items-end">
                                <a href={props.link}>
                                    <ShyButton className='whitespace-no-wrap'>
                                        {props.linkDesc
                                            ? props.linkDesc
                                            : props.link}
                                    </ShyButton>
                                </a>
                            </div>
                        )}
                    </Card>
                ) : (
                    <InfoBlockLoader
                        height={height}
                        width={width}
                        speed={2}
                        // style={{
                        //     backgroundColor: prowl.colors['prowl-blue-lighter']
                        // }}
                        // primaryColor={prowl.colors['prowl-blue-light']}
                        // secondaryColor={prowl.colors['prowl-blue-lighter']}
                    />
                )}
            </div>
        )
    )
}
