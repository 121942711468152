import React from 'react'
import { Icon } from '../../Icon'
import { Card } from '../../Card'
import { Tooltip } from '../../Tooltip'

import { ITableCellFormatter } from './interfaces/ITableCellFormatter'

export type ActionValue = {
    label: string
    href?: string
    method?: string
    onClick?: Function
}

export const Actions: React.FC<ITableCellFormatter> = (props: any) => {
    let actions = props.cell.getValue() as Array<ActionValue>
    if (typeof actions !== 'object') {
        actions = JSON.parse(actions)
    }

    const labels = actions.map((action) => action.label)

    const onSelect = (selected: ActionValue) => {
        let actionValue = actions.find(
            (action) => action.label === selected.label
        )

        if (actionValue.method == 'post') {
            console.log('posting!')
        }
        if (actionValue.href) window.location.href = actionValue.href
        else if (actionValue.onClick) actionValue.onClick()
    }

    return (
        <span className="flex flex-row justify-center text-md">
            {labels.length ? (
                <Tooltip
                    placement="right-start"
                    transformOrigin="top left"
                    content={() => (
                        <Card
                            elevated
                            className="mt-2 ml-2 bg-primary-midnight text-white"
                        >
                            <ul className="select-none block">
                                <li className="py-1 px-4 block text-secondary-light text-2xs">Actions</li>
                                {actions.map(
                                    ({ href, label, method = 'get' }) => {
                                        return (
                                            <li className="block">
                                                <button className="py-1 px-4 block w-full text-left hover:bg-primary-cobalt">{label}</button>
                                            </li>
                                        )
                                    }
                                )}
                            </ul>
                        </Card>
                    )}
                >
                    <span className="p-2 cursor-pointer">
                        <Icon size="lg" icon="ellipsis-v" className="text-primary-aluminum" />
                    </span>
                </Tooltip>
            ) : (
                ''
            )}
        </span>
    )
}

Actions.displayName = 'Actions'
