import React, { FunctionComponent } from 'react'

const Layout: FunctionComponent = props => <div />

Layout.displayName = 'Layout'

interface NavigationProps {
    className?: string
}
const Navigation: FunctionComponent<NavigationProps> = props => (
    <div
        className={`bg-primary-darker flex justify-between `.concat(
            props.className
        )}
    >
        {props.children}
    </div>
)

export { Layout, Navigation }
