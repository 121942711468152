import React, { FunctionComponent } from 'react'
import './User.css'
const User: FunctionComponent = props => <div />

User.displayName = 'User'

interface AvatarProps {
    firstName?: string
    lastName?: string
    className?: string
}

const Avatar: FunctionComponent<AvatarProps> = props => {
    const { firstName = '', lastName = '', className } = props
    return (
        <div
            className={`avatar`.concat(
                ' ',
                className
            )}
        >
            {firstName[0]}
            {lastName[0]}
        </div>
    )
}

export { User, Avatar }
