import React from 'react'
import './Pillbox.css'

interface IPillbox {
    text: string,
    className?: string
}

const Pillbox: React.FC<IPillbox> = ({ text, className = '' }) => {
    return <span className={`pawprint-pillbox`.concat(' ', className)}>{text}</span>
}

Pillbox.displayName = 'Pillbox'

export { Pillbox }
