import { library, Library, IconPack } from '@fortawesome/fontawesome-svg-core'
import {
    faExclamationTriangle,
    faInfoCircle,
    faTimesCircle,
    faTh,
    faThList,
    faQuestionCircle,
    faEllipsisV
} from '@fortawesome/free-solid-svg-icons'


export interface PawprintLibrary extends Library {
    definitions?: IconPack
}

library.add(
    faExclamationTriangle,
    faInfoCircle,
    faTimesCircle,
    faTh,
    faThList,
    faEllipsisV,
    faQuestionCircle
)

export interface IconLibAvailable {
    icon?: 'exclamation-triangle' | 'info-circle' | 'times-circle' | 'th' | 'th-list' | 'question-circle' | 'ellipsis-v'
}

export { library }
