import React from 'react'
import { Pillbox } from '../../Pillbox'
import { ITableCellFormatter } from './interfaces/ITableCellFormatter'

export const Csv: React.FC<ITableCellFormatter> = (props: any) => {
    // cannot use ?. due to terser failures https://github.com/terser/terser/issues/567
    const arrayValue: Array<string> = (props.cell.getValue() || '')
        .split(',')
        .filter(Boolean)

    return (
        <div className="inline-block">
            {arrayValue.length ? (
                <ul className="flex flex-row flex-wrap">
                    {arrayValue.map((value, i) => (
                        <li key={i} className="p-1">
                            <Pillbox text={value} />
                        </li>
                    ))}
                </ul>
            ) : (
                ''
            )}
        </div>
    )
}

Csv.displayName = 'Csv'
