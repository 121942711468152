import React from 'react'
import { ITableCellFormatter } from './interfaces/ITableCellFormatter'

export const DateTime: React.FC<ITableCellFormatter> = (props: any) => {
    const dateTime = new Date(props.cell.getValue() ?? undefined)
    const formattedTimeString = dateTime.getDate()
        ? `${dateTime.getMonth()}/${dateTime.getDate()}/${dateTime.getFullYear()}`
        : null

    return <span>{formattedTimeString}</span>
}

DateTime.displayName = 'DateTime'
