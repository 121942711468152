import React from 'react'
import { ITableCellFormatter } from './interfaces/ITableCellFormatter'

export interface ITableCellFormatterLink extends ITableCellFormatter {
    href?: string | Function
    label?: string
    children?: any
}

export const Link: React.FC<ITableCellFormatterLink> = ({
    href,
    children,
    cell,
    label,
}) => {
    const clonedChildren = children
        ? React.cloneElement(children, { cell: cell })
        : null
    const hrefString = typeof href === 'function' ? href(cell.getData()) : href

    return (
        <a href={hrefString ?? '#'}>
            {clonedChildren ?? label ?? cell.getValue()}
        </a>
    )
}

Link.displayName = 'Link'
