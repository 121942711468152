import React, { FunctionComponent } from 'react'
import './Card.css'

interface CardProps {
    className?: string
    elevated?: boolean
}

export const Card: FunctionComponent<CardProps> = ({
    children,
    className = '',
    elevated = false
}) => (
    <div
        className={`paw-card h-full hover:shadow-lg`.concat(
            ' ',
            elevated ? 'shadow-lg ' : '',
            Boolean(className) ? className : ' bg-white p-4 '
        )}
    >
        {children}
    </div>
)
