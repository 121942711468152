import React, { FunctionComponent, useState, useEffect } from 'react'
import { reactFormatter } from 'react-tabulator'
import Vibrant from 'node-vibrant'
import { Palette } from 'node-vibrant/lib/color'

interface SellerIconProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLButtonElement>,
        HTMLElement
    > {
    /**
     * Url for icon
     */
    url?: string
    type?: string
}

const domain = (url: string) => {
    if (!url) {
        return 'blank'
    }

    const parser = document.createElement('a')
    // treats url as non relative path by forcing *//* if there is no protocol
    parser.href = url.includes('http') ? url : `//${url}`

    return parser.hostname
}

export const SellerIcon: FunctionComponent<SellerIconProps> = props => {
    const { className, url = 'example.com', type = '' } = props
    const imageUrl = `https://prowl.pricespider.com/icon/${domain(url)}`
    const [bgColor, setBgColor] = useState('')
    const [textColor, setTextColor] = useState('')

    useEffect(() => {
        async function loadPalette() {
            const { Vibrant: swatch }: Palette = await Vibrant.from(
                imageUrl
            ).getPalette()

            setBgColor(swatch.bodyTextColor)
            setTextColor(swatch.hex)
        }
    }, [url])

    return (
        <div
            className={'w-6 h-6 right flex items-center justify-end flex-no-shrink'.concat(
                ' ',
                className
            )}
        >
            <img
                className="w-full h-full border border-prowl-blue-light rounded-full p-1 shadow-inner bg-white"
                src={imageUrl}
                title={type}
                alt={type}
            />
        </div>
    )
}

interface ISellerName {
    title?: string
    name?: string
    seller_id?: number
    onChange?: Function
}

export const SellerName: FunctionComponent<ISellerName> = props => {
    const { name, seller_id = '', title } = props
    return (
        <React.Fragment>
            <a
                title={title || name}
                data-seller-tooltip
                className="mb-1 block text-secondary-darker hover:text-primary underline"
                href={`/client/sellers.php?sellerId=${seller_id}`}
            >
                {name}
            </a>
        </React.Fragment>
    )
}

interface ISellerProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLButtonElement>,
        HTMLElement
    > {
    /**
     * Show Checkbox?
     * @default false
     */
    url?: string
    name?: string
    seller_id?: number
    status?: string
    className?: string
}

export const Seller: FunctionComponent<ISellerProps> = props => {
    const {
        name,
        url,
        onChange,
        seller_id,
        status = false,
        className = '',
        children
    } = props
    return (
        <div
            className={
                `items-center flex py-2`.concat(' ', className)
            }
        >
            <div className="flex items-start relative">
                <SellerIcon url={url || name} className="mr-2" />
                <div className="sellerview__seller-name leading-none">
                    <SellerName
                        seller_id={seller_id}
                        name={name}
                        onChange={onChange}
                    />
                    {status && <p>{status}</p>}
                    {children}
                </div>
            </div>
        </div>
    )
}

interface FormatterProps {
    cell?: { getValue: () => string }
}

export const SellerFormat: FunctionComponent<FormatterProps> = ({ cell }) => {
    const name = cell.getValue()
    return <div className='h-8'><Seller url={name} name={name} /></div>
}

export const SellerFormatter = reactFormatter(<SellerFormat />)
