import React, { forwardRef } from 'react'
import { ReactTabulator, reactFormatter } from 'react-tabulator'
import { IProps } from 'react-tabulator/lib/ConfigUtils'
import './Table.css'

export type ITableProps<IProps = {}> = Partial<IProps> & {
    data: any[]
    columns: any[]
    tooltips?: boolean
    layout?: string
    options?: Object
    ref?: React.MutableRefObject<any>
    children?: React.ReactNode
}

const BaseTable: React.ForwardRefRenderFunction<ITableProps> = (
    { data, columns, tooltips = false , layout, options, ...props }: ITableProps,
    ref
) => {
    return (
        <div>
            <ReactTabulator
                data={data}
                ref={ref ? (ref as React.LegacyRef<any>) : null}
                columns={columns}
                tooltips={tooltips}
                {...props}
                options={{
                    layout: layout ?? 'fitDataTable',
                    height: '100%',
                    ...options,
                }}
            />
        </div>
    )
}

const Table = forwardRef(BaseTable)

const useFormatter = reactFormatter

export { Table, useFormatter }
