import React from 'react'
import { ITableCellFormatter } from './interfaces/ITableCellFormatter'

export const YesNo: React.FC<ITableCellFormatter> = (props: any) => {
    const booleanValue = !!parseInt(props.cell.getValue())

    return <span>{booleanValue ? 'Yes' : 'No'}</span>
}

YesNo.displayName = 'YesNo'
