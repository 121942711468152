import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import './Flash.css'

interface IFlashProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLButtonElement>,
        HTMLElement
    > {
    /**
     * The type of flash you would like to use
     * @default 'info'
     */
    type: 'info' | 'success' | 'warning' | 'caution'
}

/**
 * The `Flash` component is used for standard informational feedback.
 */
const Flash: FunctionComponent<IFlashProps> = ({ children, type }) => (
    <div className={'flash' + (type ? ` flash--${type}` : '')}>{children}</div>
)

Flash.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'caution'])
}

Flash.defaultProps = {
    type: 'info'
}

Flash.displayName = 'Flash'

export { Flash }
