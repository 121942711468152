import React, {
    FunctionComponent,
    ReactNode,
    Fragment,
    useState,
    useEffect,
    useRef,
} from 'react'
import { useSpring, animated, config } from 'react-spring'

import { Manager, Reference, Popper, PopperProps } from 'react-popper'
import useOnclickOutside from 'react-cool-onclickoutside'


type TooltipProps = {
    content: React.ReactNode
    isOpen?: boolean
    transformOrigin?: string
    children: React.ReactNode
}

export const Tooltip: FunctionComponent<TooltipProps & Omit<PopperProps, 'children'>> = (props) => {
    const { content, children, isOpen: propsOpen = false, placement, transformOrigin = 'top right' } = props
    const [isOpen, setOpen] = useState(propsOpen)

    const clickInRef = useOnclickOutside(() => setOpen(false))

    const springConfig = {
        from: {
            opacity: 0,
            scale: 0.8,
            config: { duration: 100 },
            transformOrigin,
            zIndex: 9999,
        },
        to: {
            opacity: 1,
            scale: 1,
            config: { duration: 50 },
            zIndex: 9999,
        },
    }
    const animatedProps = useSpring(
        isOpen ? springConfig.to : springConfig.from
    )

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div ref={clickInRef}>
                        <div ref={ref} onClick={() => setOpen(!isOpen)}>
                            {typeof children == 'function'
                                ? children()
                                : children}
                        </div>
                    </div>
                )}
            </Reference>

            <Popper placement={placement}>
                {({ ref, style, placement, arrowProps }) => (
                    <div
                        ref={clickInRef}
                        style={{
                            zIndex: isOpen ? 'auto' : -9999,
                        }}
                    >
                        <animated.span
                            ref={ref}
                            style={{ ...style, ...animatedProps }}
                            data-placement={placement}
                        >
                            {typeof content == 'function' ? content() : content}
                        </animated.span>
                    </div>
                )}
            </Popper>
        </Manager>
    )
}
