import React, { FunctionComponent, CSSProperties } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    Transform,
    IconProp,
    FlipProp,
    SizeProp,
    PullProp,
    RotateProp,
    FaSymbol
} from '@fortawesome/fontawesome-svg-core'

export interface IIconProps {
    icon: IconProp
    mask?: IconProp
    className?: string
    color?: string
    spin?: boolean
    pulse?: boolean
    border?: boolean
    fixedWidth?: boolean
    inverse?: boolean
    listItem?: boolean
    flip?: FlipProp
    size?: SizeProp
    pull?: PullProp
    rotation?: RotateProp
    transform?: string | Transform
    symbol?: FaSymbol
    style?: CSSProperties
    tabIndex?: number
    title?: string
}

const Icon: FunctionComponent<IIconProps> = props => (
    <FontAwesomeIcon {...props} />
)

export { Icon }
