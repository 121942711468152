import React, { FunctionComponent, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'
import { Card } from '../Card'
import './Notice.css'

interface INoticeProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLButtonElement>,
        HTMLElement
    > {

   /**
    * The type of notice you would like to use
    * @default 'info'
    */       
    type: 'info' | 'success' | 'warning' | 'caution'
    /**
     * The heading of the notice box
     */
    title?: string
    icon?: 'info-circle' | 'exclamation-triangle'
}

/**
 * The `Notice` component is used for high visibility contextual messaging.
 */
const Notice: FunctionComponent<INoticeProps> = ({
    children,
    type,
    icon,
    title,
}) => (
    <div className={'notice' + (type ? ` notice--${type}` : '')}>
        <div className="notice__heading">
            {icon && (
                <div className="notice__icon">
                    <Icon icon={icon} />
                </div>
            )}
            <h4 className="notice__title">{title}</h4>
        </div>

        <div className="notice__content">{children}</div>
    </div>
)

Notice.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'caution']),
    icon: PropTypes.oneOf(['info-circle', 'exclamation-triangle'])
}

Notice.defaultProps = {
    type: 'info',
    icon: 'info-circle',
}

Notice.displayName = 'Notice'

export { Notice }
